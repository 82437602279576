import React from 'react'
import '../main.scss';

function News() {
  return (
  <>

<div>
  <div className role="region" aria-label="Latest in News and Advice">
    <div className="style--gridWrapper--3mwT46o0 style--gridWrapper--Cw9qI3Ds ">
      <div className="style--grid--DjKxggtU style--grid--hY12sI9C ">
        <div
          className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 "
          style={{ width: "100%" }}
        >
          <h5 className="MGTheme--section-title--1AA_sn5R MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-2--1cfwJ-VQ typography--sans-serif--2NTOffNS MGTheme--padding-top--1UD_KUmj styles--padding-top--kXFYA4yD MGTheme--margin-bottom--3MWVrP1Q styles--margin-bottom--1ci00iZQ ">
            <span>Latest in News and Advice</span>
          </h5>
        </div>
      </div>
    </div>
    <div className="style--gridWrapper--3mwT46o0 style--gridWrapper--Cw9qI3Ds ">
      <div className="style--grid--DjKxggtU style--grid--hY12sI9C ">
        <div
          className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 myclass2 "
          style={{ width: "50%" }}
        >
          <article
            className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--article-snippet--2OlA9fXg MGTheme--border-bottom-light--D4tmjnkn MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
            data-id="WP-MG-0000141165"
          >
            <div
              className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
              style={{ width: "140px" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "auto",
                  "padding-bottom": "56.25%",
                }}
              >
                <a
                  className
                  href="#"
                >
                  <div
                    className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                    role="img"
                    aria-label="The Newest Must-Have Home Amenity for the Rich: Purified Air"
                    style={{
                      height: "79px",
                      "margin-top": "initial",
                      "margin-bottom": "initial",
                      "background-image":
                        'url("https://images.wsj.net/im-926380/?width=620&height=349")',
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="MGTheme--headline--v_dJXUjo ">
              <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                <a
                  className
                  href="#"
                >
                  <span className>
                    The Newest Must-Have Home Amenity for the Rich: Purified Air
                  </span>
                </a>
              </h4>
            </div>
            <div className="MGTheme--section--1e7sQyVg ">
              <a
                className="MGTheme--section-link--33c2T_7C "
                href="luxury-real-estate-news/subject/subject-amenities"
              >
                amenities
              </a>
            </div>
          </article>
          <article
            className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--article-snippet--2OlA9fXg MGTheme--border-bottom-light--D4tmjnkn MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
            data-id="WP-MG-0000141112"
          >
            <div
              className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
              style={{ width: "140px" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "auto",
                  "padding-bottom": "56.25%",
                }}
              >
                <a
                  className
                  href="#"
                >
                  <div
                    className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                    role="img"
                    aria-label="Charlie Puth’s Mid-Century Modern Home in Beverly Hills Is Now $2 Million Cheaper"
                    style={{
                      height: "79px",
                      "margin-top": "initial",
                      "margin-bottom": "initial",
                      "background-image":
                        'url("https://images.mansionglobal.com/im-32322628?width=620&height=349")',
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="MGTheme--headline--v_dJXUjo ">
              <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                <a
                  className
                  href="#"
                >
                  <span className>
                    Charlie Puth’s Mid-Century Modern Home in Beverly Hills Is
                    Now $2 Million Cheaper
                  </span>
                </a>
              </h4>
            </div>
            <div className="MGTheme--section--1e7sQyVg ">
              <a
                className="MGTheme--section-link--33c2T_7C "
                href="#"
              >
                celebrity homes
              </a>
            </div>
          </article>
          <article
            className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--article-snippet--2OlA9fXg MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
            data-id="WP-MG-0000141060"
          >
            <div
              className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
              style={{ width: "140px" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "auto",
                  "padding-bottom": "56.25%",
                }}
              >
                <a
                  className
                  href="#"
                >
                  <div
                    className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                    role="img"
                    aria-label="Italy’s Luxury Homes Saw Price Growth Last Year Despite Global Economic Weakness"
                    style={{
                      height: "79px",
                      "margin-top": "initial",
                      "margin-bottom": "initial",
                      "background-image":
                        'url("https://images.mansionglobal.com/im-88096476?width=620&height=349")',
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="MGTheme--headline--v_dJXUjo ">
              <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                <a
                  className
                  href="#"
                >
                  <span className>
                    Italy’s Luxury Homes Saw Price Growth Last Year Despite
                    Global Economic Weakness
                  </span>
                </a>
              </h4>
            </div>
            <div className="MGTheme--section--1e7sQyVg ">
              <a
                className="MGTheme--section-link--33c2T_7C "
                href="#"
              >
                market reports
              </a>
            </div>
          </article>
        </div>
        <div
          className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 MGTheme--padding-left--1uZwZvUd my-class "
          style={{ width: "50%" }}
        >
          <article
            className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--article-snippet--2OlA9fXg MGTheme--border-bottom-light--D4tmjnkn MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
            data-id="WP-MG-0000141047"
          >
            <div
              className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
              style={{ width: "140px" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "auto",
                  "padding-bottom": "56.25%",
                }}
              >
                <a
                  className
                  href="#"
                >
                  <div
                    className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                    role="img"
                    aria-label="Mansion Global Daily: Record Number of Home Buyers Are Paying Cash in New York"
                    style={{
                      height: "79px",
                      "margin-top": "initial",
                      "margin-bottom": "initial",
                      "background-image":
                        'url("https://images.mansionglobal.com/im-42583883?width=620&height=349")',
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="MGTheme--headline--v_dJXUjo ">
              <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                <a
                  className
                  href="#"
                >
                  <span className>
                    Mansion Global Daily: Record Number of Home Buyers Are
                    Paying Cash in New York
                  </span>
                </a>
              </h4>
            </div>
            <div className="MGTheme--section--1e7sQyVg ">
              <a
                className="MGTheme--section-link--33c2T_7C "
                href="#"
              >
                mansion global daily
              </a>
            </div>
          </article>
          <article
            className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--article-snippet--2OlA9fXg MGTheme--border-bottom-light--D4tmjnkn MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
            data-id="WP-MG-0000141020"
          >
            <div
              className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
              style={{ width: "140px" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "auto",
                  "padding-bottom": "56.25%",
                }}
              >
                <a
                  className
                  href="#"
                >
                  <div
                    className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                    role="img"
                    aria-label="European-Style Estate Hits the Australian Market for A$48.8 Million, Making It the Priciest Home for Sale in Melbourne"
                    style={{
                      height: "79px",
                      "margin-top": "initial",
                      "margin-bottom": "initial",
                      "background-image":
                        'url("https://images.mansionglobal.com/im-55089680?width=620&height=349")',
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="MGTheme--headline--v_dJXUjo ">
              <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                <a
                  className
                  href="#"
                >
                  <span className>
                    European-Style Estate Hits the Australian Market for A$48.8
                    Million, Making It the Priciest Home for Sale in Melbourne
                  </span>
                </a>
              </h4>
            </div>
            <div className="MGTheme--section--1e7sQyVg ">
              <a
                className="MGTheme--section-link--33c2T_7C "
                href="luxury-real-estate-news/subject/subject-new-listings"
              >
                new listings
              </a>
            </div>
          </article>
          <div className="MGTheme--fallback--2l6a92Go ">
            <div id="default-latestnews" />
            <div id="fallback-latestnews" className="MGTheme--show--39Zm8R52 ">
              <article
                className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--article-snippet--2OlA9fXg MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
                data-id="WP-MG-0000140951"
              >
                <div
                  className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
                  style={{ width: "140px" }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "auto",
                      "padding-bottom": "56.25%",
                    }}
                  >
                    <a
                      className
                      href="#"
                    >
                      <div
                        className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                        role="img"
                        aria-label="Four Smart Microwaves to Upgrade Your Kitchen"
                        style={{
                          height: "79px",
                          "margin-top": "initial",
                          "margin-bottom": "initial",
                          "background-image":
                            'url("https://images.mansionglobal.com/im-45712210?width=620&height=349")',
                        }}
                      />
                    </a>
                  </div>
                </div>
                <div className="MGTheme--headline--v_dJXUjo ">
                  <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                    <a
                      className
                      href="#"
                    >
                      <span className>
                        Four Smart Microwaves to Upgrade Your Kitchen
                      </span>
                    </a>
                  </h4>
                </div>
                <div className="MGTheme--section--1e7sQyVg ">
                  <a
                    className="MGTheme--section-link--33c2T_7C "
                    href="#"
                  >
                    smart homes
                  </a>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="style--gridWrapper--3mwT46o0 style--gridWrapper--Cw9qI3Ds ">
      <div className="style--grid--DjKxggtU style--grid--hY12sI9C ">
        <div
          className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 "
          style={{ width: "100%" }}
        >
          <div className="MGTheme--link-container--1LsmGspS typography--sans-serif--2NTOffNS MGTheme--link-container-view-all--es2pVaJq ">
            <a href="#">
              View all latest in news &amp; advice
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>;

  </>
  )
}

export default News
