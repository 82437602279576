import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import { detectOverflow } from '@popperjs/core';


import Home from "./components/Header/Index";


import './App.scss'



const App = () => {
    return (
        <Router>
            <Routes>
              
                    <Route path="/" element={<Home />} />
                  
               
                
            </Routes>
        </Router>
    )
}
export default App;