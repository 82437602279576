import React, { useState, useRef } from 'react';
import '../main.scss';
import { Carousel } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

function Banner() {
  // State to track whether the video is playing or paused
  const [isPlaying, setIsPlaying] = useState(false);
  // Reference to the video element
  const videoRef = useRef(null);

  // Function to toggle play/pause
  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="carousel-container mt-5">
      <Carousel>
        <Carousel.Item>
          {/* Render the main video only in the first carousel item */}
          <div className="style--video-wrapper--75s1uPcE ">
            <div className="style--virtualListingLabel--3BNs93T2 ">Virtual listing</div>
            {/* Video element with controls */}
            <div className="video-container">
              <video width="100%" controls ref={videoRef}>
                <source src="https://424ab3360cd45b4ab42b-eaef829eae7c04fd12005cc3ad780db0.ssl.cf1.rackcdn.com/m0qcfbbd6c01li8u_13c9e5af_720p.mp4" />
                Your browser does not support the video tag.
              </video>
              {/* Play/Pause button */}
              <button className="play-pause-button" onClick={togglePlayPause}>
              {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
              </button>
            </div>
          </div>
        </Carousel.Item>

        
        <Carousel.Item>
          {/* Leave other carousel items as they are */}
          <img className="d-block " src='https://static.mansionglobal.com/production/media/listing_images/088cc1f4093e05b9eb77a1a2abbbbbbd/large_77ac1304f5a8d9d276b5a56071be19.jpg' alt="Second slide" />
          <div className="carousel-caption mt-3 d-none d-md-block">
            <h5>Second slide label</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </Carousel.Item>
    
      </Carousel>
    </div>
  );
}

export default Banner;
