import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../main.scss';
import { Carousel } from 'react-bootstrap';

function Developer() {
    const settings = {
        centerMode: true,
        centerPadding: '10%',
        slidesToShow: 1,
        infinite: true,
        arrows: false,
        dots: false,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerPadding: '5%',
                },
            },
        ],
    };





    return (
        <>

            <div className="style--gridWrapper--3mwT46o0 style--gridWrapper--Cw9qI3Ds ">
                <div className="style--grid--DjKxggtU style--grid--hY12sI9C ">
                    <div
                        className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 "
                        style={{
                            width: '100%'
                        }}
                    >
                        <h5 className="MGTheme--section-title--1AA_sn5R MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-2--1cfwJ-VQ typography--sans-serif--2NTOffNS MGTheme--padding-top--1UD_KUmj styles--padding-top--kXFYA4yD MGTheme--margin-bottom--3MWVrP1Q styles--margin-bottom--1ci00iZQ ">
                            <span>
                                FEATURED NEW DEVELOPMENTS
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="slider-container2 " >
                <Slider {...settings}>
                    <div className="image-container2">
                        <img src="https://static3.mansionglobal.com/production/media/listing_images/c607b9d84f6a57bc566b2443f3c7be79/large_fb480164f207acc07366da226c6998.jpg" alt="Image 1" />
                    </div>
                    <div className="image-container2">
                        <img src="https://static3.mansionglobal.com/production/media/listing_images/c607b9d84f6a57bc566b2443f3c7be79/large_fb480164f207acc07366da226c6998.jpg" alt="Image 2" />
                    </div>
                    <div className="image-container2">
                        <img src="https://static3.mansionglobal.com/production/media/listing_images/c607b9d84f6a57bc566b2443f3c7be79/large_fb480164f207acc07366da226c6998.jpg" alt="Image 2" />
                    </div>
                    <div className="image-container2">
                        <img src="https://static3.mansionglobal.com/production/media/listing_images/c607b9d84f6a57bc566b2443f3c7be79/large_fb480164f207acc07366da226c6998.jpg" alt="Image 2" />
                    </div>
                    <div className="image-container2">
                        <img src="https://static3.mansionglobal.com/production/media/listing_images/c607b9d84f6a57bc566b2443f3c7be79/large_fb480164f207acc07366da226c6998.jpg" alt="Image 2" />
                    </div>
                    <div className="image-container2">
                        <img src="https://static3.mansionglobal.com/production/media/listing_images/c607b9d84f6a57bc566b2443f3c7be79/large_fb480164f207acc07366da226c6998.jpg" alt="Image 2" />
                    </div>
                    {/* Add more images as needed */}
                </Slider>
            </div>
        </>
    );
}

export default Developer;
