import React from "react";
import '../main.scss';
function TopStories() {
  return (
    <>
      <div className="" role="region" aria-label="Top Stories">
        <div
          className="style--gridWrapper--3mwT46o0 style--gridWrapper--Cw9qI3Ds "
          id="topStories"
        >
          <div className="style--grid--DjKxggtU style--grid--hY12sI9C ">
            <div
              className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 "
              style={{ width: "100%" }}
            >
              <h5 className="MGTheme--section-title--1AA_sn5R MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-2--1cfwJ-VQ typography--sans-serif--2NTOffNS MGTheme--padding-top--1UD_KUmj styles--padding-top--kXFYA4yD MGTheme--margin-bottom--3MWVrP1Q styles--margin-bottom--1ci00iZQ ">
                <span>Top Stories</span>
              </h5>
            </div>
          </div>
        </div>
        <div className="style--gridWrapper--3mwT46o0 style--gridWrapper--Cw9qI3Ds ">
          <div className="style--grid--DjKxggtU style--grid--hY12sI9C ">
            <div
              className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 "
              style={{ width: "75%" }}
            >
              <div>
                <div
                  className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--accessible--3PlRSFi2 "
                  data-id="WP-MG-0000141112"
                >
                  <div
                    className="MGTheme--image--149NSQIk MGTheme--image-above--yrgZOqG5 "
                    style={{ width: "100%" }}
                  >
                    <a
                      className=""
                      href="#"
                    >
                      <div
                        className="MGTheme--mg-image--3V4d5jll "
                        role="img"
                        aria-label="Charlie Puth’s Mid-Century Modern Home in Beverly Hills Is Now $2 Million Cheaper"
                        style={{
                          height: 460,
                          marginTop: "initial",
                          marginBottom: "initial",
                          backgroundImage:
                            'url("https://images.mansionglobal.com/im-32322628?width=1280&height=853")',
                        }}
                      />
                    </a>
                  </div>
                  <div className="MGTheme--headline--v_dJXUjo ">
                    <h1 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-1--3MjD7Hgo typography--serif--2ydmXnYH ">
                      <a
                        className=""
                        href="#"
                      >
                        <span className="">
                          Charlie Puth’s Mid-Century Modern Home in Beverly
                          Hills Is Now $2 Million Cheaper
                        </span>
                      </a>
                    </h1>
                  </div>
                  <div className="MGTheme--section--1e7sQyVg ">
                    <a
                      className="MGTheme--section-link--33c2T_7C "
                      href="#"
                    >
                      celebrity homes
                    </a>
                  </div>
                  <p className="MGTheme--summary--dBQ8zrTc typography--sans-serif--2NTOffNS ">
                    <span className="">
                      The notable property by California architect Rex Lotery
                      has gotten two back-to-back price cuts since mid-February
                      and is now asking nearly $15 million
                    </span>
                  </p>
                </div>
              </div>
              <div className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 ">
                <div
                  className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--div-snippet--2OlA9fXg MGTheme--border-bottom-light--D4tmjnkn MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
                  data-id="WP-MG-0000141060"
                >
                  <div
                    className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
                    style={{ width: 140 }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "auto",
                        paddingBottom: "56.25%",
                      }}
                    >
                      <a
                        className=""
                        href="#"
                      >
                        <div
                          className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                          role="img"
                          aria-label="Italy’s Luxury Homes Saw Price Growth Last Year Despite Global Economic Weakness"
                          style={{
                            height: 79,
                            marginTop: "initial",
                            marginBottom: "initial",
                            backgroundImage:
                              'url("https://images.mansionglobal.com/im-88096476?width=620&height=349")',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="MGTheme--headline--v_dJXUjo ">
                    <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                      <a
                        className=""
                        href="#"
                      >
                        <span className="">
                          Italy’s Luxury Homes Saw Price Growth Last Year
                          Despite Global Economic Weakness
                        </span>
                      </a>
                    </h4>
                  </div>
                  <div className="MGTheme--section--1e7sQyVg ">
                    <a
                      className="MGTheme--section-link--33c2T_7C "
                      href="luxury-real-estate-news/subject/subject-market-reports"
                    >
                      market reports
                    </a>
                  </div>
                </div>
                <div
                  className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--div-snippet--2OlA9fXg MGTheme--border-bottom-light--D4tmjnkn MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
                  data-id="WP-MG-0000141047"
                >
                  <div
                    className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
                    style={{ width: 140 }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "auto",
                        paddingBottom: "56.25%",
                      }}
                    >
                      <a
                        className=""
                        href="#"
                      >
                        <div
                          className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                          role="img"
                          aria-label="Mansion Global Daily: Record Number of Home Buyers Are Paying Cash in New York"
                          style={{
                            height: 79,
                            marginTop: "initial",
                            marginBottom: "initial",
                            backgroundImage:
                              'url("https://images.mansionglobal.com/im-42583883?width=620&height=349")',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="MGTheme--headline--v_dJXUjo ">
                    <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                      <a
                        className=""
                        href="#"
                      >
                        <span className="">
                          Mansion Global Daily: Record Number of Home Buyers Are
                          Paying Cash in New York
                        </span>
                      </a>
                    </h4>
                  </div>
                  <div className="MGTheme--section--1e7sQyVg ">
                    <a
                      className="MGTheme--section-link--33c2T_7C "
                      href="luxury-real-estate-news/subject/subject-mansion-global-daily"
                    >
                      mansion global daily
                    </a>
                  </div>
                </div>
                <div
                  className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--div-snippet--2OlA9fXg MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
                  data-id="WP-MG-0000140952"
                >
                  <div
                    className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
                    style={{ width: 140 }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "auto",
                        paddingBottom: "56.25%",
                      }}
                    >
                      <a
                        className=""
                        href="#"
                      >
                        <div
                          className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                          role="img"
                          aria-label="Buyers Finally Have Homes to Choose From This Spring, as U.S. Housing Inventory Perks Up"
                          style={{
                            height: 79,
                            marginTop: "initial",
                            marginBottom: "initial",
                            backgroundImage:
                              'url("https://images.mansionglobal.com/im-28816251?width=620&height=349")',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="MGTheme--headline--v_dJXUjo ">
                    <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                      <a
                        className=""
                        href="#"
                      >
                        <span className="">
                          Buyers Finally Have Homes to Choose From This Spring,
                          as U.S. Housing Inventory Perks Up
                        </span>
                      </a>
                    </h4>
                  </div>
                  <div className="MGTheme--section--1e7sQyVg ">
                    <a
                      className="MGTheme--section-link--33c2T_7C "
                      href="luxury-real-estate-news/subject/subject-market-reports"
                    >
                      market reports
                    </a>
                  </div>
                </div>
              </div>
              <div className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 MGTheme--padding-left--1uZwZvUd ">
                <div
                  className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--div-snippet--2OlA9fXg MGTheme--border-bottom-light--D4tmjnkn MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
                  data-id="WP-MG-0000140951"
                >
                  <div
                    className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
                    style={{ width: 140 }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "auto",
                        paddingBottom: "56.25%",
                      }}
                    >
                      <a
                        className=""
                        href="#"
                      >
                        <div
                          className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                          role="img"
                          aria-label="Four Smart Microwaves to Upgrade Your Kitchen"
                          style={{
                            height: 79,
                            marginTop: "initial",
                            marginBottom: "initial",
                            backgroundImage:
                              'url("https://images.mansionglobal.com/im-45712210?width=620&height=349")',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="MGTheme--headline--v_dJXUjo ">
                    <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                      <a
                        className=""
                        href="#"
                      >
                        <span className="">
                          Four Smart Microwaves to Upgrade Your Kitchen
                        </span>
                      </a>
                    </h4>
                  </div>
                  <div className="MGTheme--section--1e7sQyVg ">
                    <a
                      className="MGTheme--section-link--33c2T_7C "
                      href="luxury-real-estate-news/lifestyle/lifestyle-smart-home"
                    >
                      smart homes
                    </a>
                  </div>
                </div>
                <div
                  className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--div-snippet--2OlA9fXg MGTheme--border-bottom-light--D4tmjnkn MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
                  data-id="WP-MG-0000140732"
                >
                  <div
                    className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
                    style={{ width: 140 }}
                  >
                    <a
                      className="playIcon-styles--playIcon--17oVv6oP"
                      href="#"
                      aria-label="Timber Ski Lodge in the French Alps Gives off Rustic Vibes"
                      style={{ height: 79 }}
                    >
                      <svg
                        version="1.1"
                        x="0px"
                        y="0px"
                        width="100%"
                        height="100%"
                        viewBox="0 0 52 52"
                        enableBackground="new 0 0 52 52"
                        style={{ width: "40%", height: "40%" }}
                      >
                        <g id="XMLID_2_" opacity={1}>
                          <path
                            fill="#FFFFFF"
                            d="M26,51.5C11.9,51.5,0.5,40.1,0.5,26C0.5,11.9,11.9,0.5,26,0.5c14.1,0,25.5,11.4,25.5,25.5C51.5,40.1,40.1,51.5,26,51.5z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M26,1c13.8,0,25,11.2,25,25S39.8,51,26,51S1,39.8,1,26S12.2,1,26,1 M26,0C11.7,0,0,11.7,0,26s11.7,26,26,26 s26-11.7,26-26S40.3,0,26,0L26,0z"
                          />
                        </g>
                        <polygon
                          id="XMLID_1_"
                          fill="#333333"
                          points="19.6,37.5 19.6,14.5 37.8,25.5 "
                        />
                      </svg>
                    </a>
                    <div
                      style={{
                        position: "relative",
                        width: "auto",
                        paddingBottom: "56.25%",
                      }}
                    >
                      <a
                        className=""
                        href="#"
                      >
                        <div
                          className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                          role="img"
                          aria-label="Timber Ski Lodge in the French Alps Gives off Rustic Vibes"
                          style={{
                            height: 79,
                            marginTop: "initial",
                            marginBottom: "initial",
                            backgroundImage:
                              'url("https://images.mansionglobal.com/im-35599061?width=620&height=349")',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="MGTheme--headline--v_dJXUjo ">
                    <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                      <a
                        className=""
                        href="#"
                      >
                        <span className="">
                          Timber Ski Lodge in the French Alps Gives off Rustic
                          Vibes
                        </span>
                      </a>
                    </h4>
                  </div>
                  <div className="MGTheme--section--1e7sQyVg ">
                    <a
                      className="MGTheme--section-link--33c2T_7C "
                      href="#"
                    >
                      listing of the day
                    </a>
                  </div>
                </div>
                <div className="MGTheme--fallback--2l6a92Go ">
                  <div id="default-topstories">
                    <div
                      className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--div-snippet--2OlA9fXg MGTheme--special-report--3SJ6syqD MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
                      data-id="WP-MG-0000140114"
                    >
                      <div
                        className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
                        style={{ width: 140 }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "auto",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <a
                            className=""
                            href="#"
                          >
                            <div
                              className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                              role="img"
                              aria-label="Rooms With a View—of Iconic World Sites"
                              style={{
                                height: 79,
                                marginTop: "initial",
                                marginBottom: "initial",
                                backgroundImage:
                                  'url("https://images.mansionglobal.com/im-23018973?width=620&height=349")',
                              }}
                            />
                          </a>
                        </div>
                      </div>
                      <div className="MGTheme--section--1e7sQyVg ">
                        special report
                      </div>
                      <div className="MGTheme--headline--v_dJXUjo ">
                        <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                          <a
                            className=""
                            href="#"
                          >
                            <span className="">
                              Rooms With a View—of Iconic World Sites
                            </span>
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    id="fallback-topstories"
                    className="MGTheme--hide--YNgPfAlQ "
                  >
                    <div
                      className="MGTheme--story--HR_euMUB typography--sans-serif--2NTOffNS MGTheme--div-snippet--2OlA9fXg MGTheme--accessible--3PlRSFi2 MGTheme--padding-top--2dbe60hE styles--padding-top--kXFYA4yD MGTheme--padding-bottom--3iOY38me styles--padding-bottom--3G71JtW2 MGTheme--margin-right--1_rjngim styles--margin-right--3J2qtqBM "
                      data-id="WP-MG-0000141006"
                    >
                      <div
                        className="MGTheme--image--149NSQIk MGTheme--image-left--3zYx_qgN "
                        style={{ width: 140 }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "auto",
                            paddingBottom: "56.25%",
                          }}
                        >
                          <a
                            className=""
                            href="#"
                          >
                            <div
                              className="MGTheme--mg-image--3V4d5jll MGTheme--lazyload--2DdyuPyW "
                              role="img"
                              aria-label="Architectural, Japanese-Inspired New Build to List in L.A. for Nearly $30 Million"
                              style={{
                                height: 79,
                                marginTop: "initial",
                                marginBottom: "initial",
                                backgroundImage:
                                  'url("https://images.mansionglobal.com/im-68855622?width=620&height=349")',
                              }}
                            />
                          </a>
                        </div>
                      </div>
                      <div className="MGTheme--headline--v_dJXUjo ">
                        <h4 className="MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-4--1VakY_9Z typography--serif--2ydmXnYH ">
                          <a
                            className=""
                            href="#"
                          >
                            <span className="">
                              Architectural, Japanese-Inspired New Build to List
                              in L.A. for Nearly $30 Million
                            </span>
                          </a>
                        </h4>
                      </div>
                      <div className="MGTheme--section--1e7sQyVg ">
                        <a
                          className="MGTheme--section-link--33c2T_7C "
                          href="luxury-real-estate-news/location/market-beverly-hills"
                        >
                          beverly hills
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="MGTheme--link-container--1LsmGspS typography--sans-serif--2NTOffNS MGTheme--link-container-view-all--es2pVaJq ">
                <a href="#">
                  View all luxury real estate news
                </a>
              </div>
            </div>
            <div
              className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 "
              style={{ width: "25%" }}
            >
              <div className="MGTheme--positionRelative--Y4IA0uVU ">
                <div style={{ height: 1050 }}>
                  <div style={{ position: "absolute", height: 1050 }}>
                    <div>
                      <div style={{ paddingBottom: 0 }} />
                      <div
                        className=""
                        style={{ transform: "translateZ(0px)" }}
                      >
                        <div
                          className="MGTheme--adWrapper--WXHVYVoJ "
                          tabIndex={0}
                          id="wrapper-AD_RIGHT"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            minHeight: 250,
                          }}
                        >
                          <div className="">
                            <div
                              id="AD_RIGHT"
                              className="MGTheme--adContainer--1cYagxTN  "
                              style={{}}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr
                    style={{
                      border: "0px solid transparent",
                      margin: 0,
                      padding: 0,
                      height: 0,
                      width: 0,
                      overflow: "hidden",
                      background: "transparent",
                      position: "absolute",
                      bottom: 20,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopStories;
