import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../main.scss';

function ExploreTopMarket() {

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 5,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1
                }
            }
        ]
    };



    const products = [
      { id: 1, image: 'https://static3.mansionglobal.com/production/media/development_images/7522740199e124f833152ebf977ba6d5/small_01_Main_Image.jpg', label: 'Luxury Villa'  },
      { id: 2, image: 'https://static1.mansionglobal.com/production/media/development_images/9480170b9865cbed3e02e624d6dd9f58/small_CLIFFS_wakeboard_aerial_rt__2_.jpg', label: 'Beachfront Condo'  },
      { id: 3, image: 'https://static1.mansionglobal.com/production/media/development_images/1f3ca484896610f1fbd0819cde1ea4ab/small_1.jpg', label: 'Mountain Retreat'  },
      { id: 4, image: 'https://static1.mansionglobal.com/production/media/development_images/1f3ca484896610f1fbd0819cde1ea4ab/small_1.jpg', label: 'Urban Loft' },
      { id: 5, image: 'https://static1.mansionglobal.com/production/media/development_images/1f3ca484896610f1fbd0819cde1ea4ab/small_1.jpg' , label: 'Lakefront Cabin' },
      { id: 6, image: 'https://static1.mansionglobal.com/production/media/development_images/1f3ca484896610f1fbd0819cde1ea4ab/small_1.jpg', label: 'Coastal Retreat'  },
      { id: 7, image: 'https://static1.mansionglobal.com/production/media/development_images/1f3ca484896610f1fbd0819cde1ea4ab/small_1.jpg', label: 'City Penthouse'  },
      { id: 8, image: 'https://static1.mansionglobal.com/production/media/development_images/1f3ca484896610f1fbd0819cde1ea4ab/small_1.jpg', label: 'Country Estate'  },
  ];
  
  return (
    <>

<div className="style--gridWrapper--3mwT46o0 style--gridWrapper--Cw9qI3Ds ">
  <div className="style--grid--DjKxggtU style--grid--hY12sI9C ">
    <div
      className="GridStyles--column--3NYZgG1V GridStyles--column-top--YAZjyc67 MGTheme--column--31vjH8Q5 "
      style={{
        width: '100%'
      }}
    >
      <h5 className="MGTheme--section-title--1AA_sn5R MGTheme--headline--3hzJC3uF typography--sans-serif--2NTOffNS MGTheme--heading-serif-2--1cfwJ-VQ typography--sans-serif--2NTOffNS MGTheme--padding-top--1UD_KUmj styles--padding-top--kXFYA4yD MGTheme--margin-bottom--3MWVrP1Q styles--margin-bottom--1ci00iZQ ">
        <span>
        EXPLORE TOP MARKETS
        </span>
      </h5>
    </div>
  </div>
</div>
            <div className="slider-container">
                <Slider {...settings}>
                    {products.map((product) => (
                         <div key={product.id} className="product-card">
                         <div className="image-container">
                             <img src={product.image} alt={`Product ${product.id}`} />
                             <div className="label">{product.label}</div>
                         </div>
                       
                     </div>
                    ))}
                </Slider>
            </div>
    </>
  )
}

export default ExploreTopMarket
